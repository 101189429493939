import type {
  TypeScheduleAmended,
  TypeDisplaySchedule,
  TypeScheduleException,
  TypeScheduleWithDate,
} from '@lib/location-types'
import { addDays, format, getDay, getHours, getMinutes, parse } from 'date-fns'

import { groupBy } from 'lodash'

export type OperationalStatus = 'Open' | 'Closed' | 'Open 24 hours' | 'Hidden'

type LocationHoursSummary = {
  operationalStatus: OperationalStatus
  nextOpenTime?: string
  locationExceptionText?: string
}

type ExceptionResult = {
  id: number | undefined
  from_time: string
  to_time: string
  isException: boolean
  exceptionMessage: string | undefined
  date: string
  type: 'Closed' | '24 Hours' | 'Open' | 'Split' | 'Hidden' | 'Regular Hours'
} | null

type OpenTimeResult = {
  nextOpenTime: string
  locationExceptionText?: string
}

const convertTo12HourFormat = (time: string | undefined): string => {
  if (!time) return ''
  const [hour, minute] = time.split(':')
  const date = new Date()
  date.setHours(parseInt(hour, 10))
  date.setMinutes(parseInt(minute, 10))
  return format(date, "h:mm aaaaa'm'")
}

const setHours = (item: any) => {
  return [convertTo12HourFormat(item?.from_time ?? ''), convertTo12HourFormat(item?.to_time ?? '')]
}

const findExceptionForDate = (
  scheduleItem: any,
  scheduleExceptions: Readonly<TypeScheduleException[]>
): ExceptionResult => {
  const exception = scheduleExceptions.find((exception) => {
    return exception.date === scheduleItem.date
  })
  if (!exception) return null

  const baseException = {
    id: exception.id,
    from_time: '',
    to_time: '',
    isException: true,
    date: exception.date,
    type: exception.type,
    // Removing the parens from the exception message
    exceptionMessage: exception.description?.replace(/[\(\)]/g, '').trim(),
  }

  switch (exception.type) {
    case 'Closed':
      return baseException
    case '24 Hours':
      return {
        ...baseException,
        from_time: '0:00',
        to_time: '24:00',
      }
    default:
      return {
        ...baseException,
        from_time: exception.from_time ?? '',
        to_time: exception.to_time ?? '',
      }
  }
}

const reformatToDisplayDay = (
  items: TypeScheduleWithDate[],
  dayLabels: string[],
  scheduleExceptions: Readonly<TypeScheduleException[]>
): TypeDisplaySchedule => {
  const exception = findExceptionForDate(items[0], scheduleExceptions)
  const { from_time, to_time, id, day, type, date } = items[0]

  // This check is to handle bad data from the ETL migration from 82web; case: open status with null times
  const open_timesAreNullish =
    type === 'Open' &&
    (from_time === null || from_time === undefined || to_time === null || to_time === undefined)

  const baseDisplaySchedule = {
    id,
    day,
    date,
    type: open_timesAreNullish ? ('Hidden' as const) : type,
    hours: items.map((item) => setHours(open_timesAreNullish ? {} : item)),
    isException: false,
    exceptionMessage: '',
    dayText: dayLabels[items[0].day - 1],
  }

  if (exception)
    return {
      ...baseDisplaySchedule,
      id: exception.id,
      type: open_timesAreNullish ? ('Hidden' as const) : exception.type,
      hours: [setHours(exception)],
      isException: true,
      exceptionMessage: exception.exceptionMessage,
    }
  else {
    return baseDisplaySchedule
  }
}

const determineNextOpenTime = (day: number, schedule: TypeDisplaySchedule[]): OpenTimeResult => {
  const dayIndex = schedule.findIndex((scheduleItem) => scheduleItem.day === day)

  // Re-order the schedule from the day after today
  const dayAfterTodaySubArray = schedule.slice(dayIndex + 1)
  const dayBeforeTodaySubArray = schedule.slice(0, dayIndex + 1)
  const orderedFromTomorrow = [...dayAfterTodaySubArray, ...dayBeforeTodaySubArray]

  // Find the next open day based on schedule type
  const nextOpenDay = orderedFromTomorrow.find(
    (scheduleItem) => scheduleItem.type && ['Open', 'Split', '24 Hours'].includes(scheduleItem.type)
  )

  if (nextOpenDay) {
    let nextOpenTime = ''

    const isNextOpenDayTomorrow = nextOpenDay.day === (day % 7) + 1

    if (!isNextOpenDayTomorrow) {
      nextOpenTime = `opens ${nextOpenDay.dayText} ${nextOpenDay.hours[0][0]}`
    } else {
      nextOpenTime = `opens tomorrow ${nextOpenDay.hours[0][0]}`
    }

    return {
      nextOpenTime,
      locationExceptionText: nextOpenDay.exceptionMessage,
    }
  }

  return { nextOpenTime: '', locationExceptionText: '' }
}

const processScheduleSummary = (
  dateToday: string,
  scheduleForToday: TypeDisplaySchedule,
  weeklyScheduleConverted: TypeDisplaySchedule[]
): LocationHoursSummary => {
  let operationalStatus
  let nextOpenTime = ''
  let locationExceptionText = scheduleForToday?.exceptionMessage
  const currentTimeString = format(new Date(dateToday), 'h:mm a')

  const getMinutesSinceMidnight = (timeString: string): number => {
    const time = parse(timeString, 'h:mm a', new Date(dateToday))
    return getHours(time) * 60 + getMinutes(time)
  }

  const currentTotalMinutes = getMinutesSinceMidnight(currentTimeString)

  switch (scheduleForToday.type) {
    case 'Hidden':
      operationalStatus = 'Hidden' as const
      break
    case '24 Hours':
      operationalStatus = 'Open 24 hours' as const
      break
    case 'Split':
    case 'Open':
      const activeTimeSlot = scheduleForToday.hours.find((timeSlot) => {
        const startTotalMinutesSlot = getMinutesSinceMidnight(timeSlot[0])
        const endTotalMinutesSlot = getMinutesSinceMidnight(timeSlot[1])
        return (
          currentTotalMinutes >= startTotalMinutesSlot && currentTotalMinutes <= endTotalMinutesSlot
        )
      })

      if (activeTimeSlot) {
        operationalStatus = 'Open' as const
        nextOpenTime = `closes ${activeTimeSlot[1]}`
      }
      // If current time is before the very first time slot
      else if (currentTotalMinutes < getMinutesSinceMidnight(scheduleForToday.hours[0][0])) {
        operationalStatus = 'Closed' as const
        nextOpenTime = `opens ${scheduleForToday.hours[0][0]}`
      }
      // If it's a split day and current time is in between the time slots
      else if (
        scheduleForToday.type === 'Split' &&
        currentTotalMinutes > getMinutesSinceMidnight(scheduleForToday.hours[0][1]) &&
        currentTotalMinutes < getMinutesSinceMidnight(scheduleForToday.hours[1][0])
      ) {
        operationalStatus = 'Closed' as const
        nextOpenTime = `opens ${scheduleForToday.hours[1][0]}`
      }
      // Else, current time is after the very last time slot
      else {
        operationalStatus = 'Closed' as const
        const result = determineNextOpenTime(scheduleForToday.day, weeklyScheduleConverted)
        nextOpenTime = result.nextOpenTime
        locationExceptionText = result.locationExceptionText
      }
      break
    default:
      operationalStatus = 'Closed' as const
      const result = determineNextOpenTime(scheduleForToday.day, weeklyScheduleConverted)
      nextOpenTime = result.nextOpenTime
      locationExceptionText = result.locationExceptionText
      break
  }

  return {
    operationalStatus,
    nextOpenTime,
    locationExceptionText,
  }
}

const createOneBasedDayIndex = (dateToday: string): number => {
  // new Date() constructor:
  //  if there's a string passed in, it still KEEPS the TIME ZONE INFORMATION
  const dayOfWeek = getDay(new Date(dateToday))

  // Adjusting for index-1 based system where Monday is 1 and Saturday is 6 (and Sunday is 7)
  return dayOfWeek === 0 ? 7 : dayOfWeek
}

const getDateForDayIndex = (dayIndex: number, dateToday: string): string => {
  // Get the difference in days.
  let daysDifference = dayIndex - createOneBasedDayIndex(dateToday)

  // If daysDifference is negative, it means the day is before today, so add 7 to it.
  if (daysDifference < 0) {
    daysDifference += 7
  }

  // Add the difference in days to today's date.
  const dateForDayIndex = addDays(new Date(dateToday), daysDifference)

  // Return the formatted date.
  return format(dateForDayIndex, 'yyyy-MM-dd')
}

// public methods:

export const convertToDisplayReadySchedule = (
  dateToday: string,
  schedule: readonly TypeScheduleAmended[],
  scheduleExceptions: Readonly<TypeScheduleException[]>
): TypeDisplaySchedule[] => {
  const dayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const oneBasedTodayIndex = createOneBasedDayIndex(dateToday)

  const scheduleWithDates = schedule.map((item) => {
    return {
      ...item,
      date: getDateForDayIndex(item.day, dateToday),
    }
  })

  // Had to group by duplicate days containing spit times - necessitates grouping by day
  const scheduleGroupedByDay: { [key: number]: TypeScheduleWithDate[] } = groupBy(
    scheduleWithDates,
    'day'
  ) //?

  // All of the logic and reshaping of the data happens in refomatToDisplayDay
  const displaySchedule = Object.values(scheduleGroupedByDay).map((items) =>
    reformatToDisplayDay(items, dayLabels, scheduleExceptions)
  )

  // break up the array and show rolling week from today + 6 days out
  const startWeek = displaySchedule.filter((item) => item.day && item.day >= oneBasedTodayIndex)
  const endWeek = displaySchedule.filter((item) => item.day && item.day < oneBasedTodayIndex)

  return [...startWeek, ...endWeek]
}

export const getScheduleSummaryInfo = (
  dateToday: string,
  weeklyScheduleConverted: TypeDisplaySchedule[]
) => {
  const oneBasedTodayIndex = createOneBasedDayIndex(dateToday)
  const scheduleForToday = weeklyScheduleConverted.find((item) => item.day === oneBasedTodayIndex)

  const scheduleSummary = scheduleForToday
    ? processScheduleSummary(dateToday, scheduleForToday, weeklyScheduleConverted)
    : null

  const locationExceptionText = scheduleSummary?.locationExceptionText
    ?.replace(/[\(\)]/g, '')
    .trim()

  return {
    operationalStatus: scheduleSummary?.operationalStatus,
    nextOpenTime: scheduleSummary?.nextOpenTime,
    locationExceptionText,
  } as LocationHoursSummary
}
